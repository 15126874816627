import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Alert from '@mui/material/Alert';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    links: state.objects.links,
});


const Card = styled(MuiCard)(({ theme }) => ({
    width: '100%', 
    display: 'flex', 
    justifyContent: 'space-between', 
    flexDirection: 'column' ,
    padding: theme.spacing(1),
  }));


class UserInfoBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPass: false,
            copied: false,
            messageSent: false,
            tmid: null,
        }
        this.handleEyeClick = this.handleEyeClick.bind(this);
        this.handleCopyClick = this.handleCopyClick.bind(this);
        this.handleMessageSuccess = this.handleMessageSuccess.bind(this);
    }

    handleMessageSuccess(){
        this.setState({
            messageSent: true,
        });
    }
    
    handleEyeClick() {
        const {showPass} = this.state;
        this.setState({
            showPass: !showPass
        })
    }

    fadeOutCopy(){
        const {tm} = this.state;
        const that = this;
        if(tm){
            clearTimeout(tm);
        }
        const tm2 = setTimeout(function(){
            that.setState({
                copied: false
            })
        }, 2000);
        this.setState({tm: tm2});
    }

    handleCopyClick(){
        const {links, hashid} = this.props;
        const current = links[hashid];
        if(!current){
            return null;
        }
        const text = current.content_protection_password;
        if ('clipboard' in navigator) {
            this.setState({
                copied: true
            })
            navigator.clipboard.writeText(text);
            this.fadeOutCopy();
        }

    }

    renderAccessAlert(link, isExpired){
        if(isExpired){
            return (<Alert severity="error">Access expired the {link.expiration_date_str}</Alert>);
        }
        return (<Alert severity="info">Access expiration date: {link.expiration_date_str} </Alert>);
    }

    render() {
        const {links, hashid} = this.props;
        const current = links[hashid];
        if(!current){
            return null;
        }
        
        const {showPass, copied} = this.state;

        return (
            <Card>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Data is encrypted!
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        For the highest protection, your data will be downloaded in an encrypted state!
                    </Typography>
                    <Typography variant="subtitle2">
                        Decryption passphrase:
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" fontFamily="monospace">
                            {showPass ? current.content_protection_password : "••••••••••••••••••••"}
                        </Typography>
                        <IconButton aria-label="Show password" onClick={this.handleEyeClick}>
                            <RemoveRedEyeRoundedIcon />
                        </IconButton>
                        <IconButton aria-label="Copy password" onClick={this.handleCopyClick}>
                            <ContentCopyIcon />
                        </IconButton>
                        <Fade in={copied} timeout={200}>
                            <Typography variant="caption" color="success.main">
                                Copied!
                            </Typography>
                        </Fade>
                    </Box>
                    <Alert severity="info" style={{ marginTop: '1rem' }}>
                        Access expiration: {current.expiration_date_str}
                    </Alert>
                </CardContent>
            </Card>
        );
    }
}

export default wrapper(UserInfoBox, {
    conn: [mapStateToProps, null],
    isAdmin: false,
});
