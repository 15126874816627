import React from 'react';
import { Container, Typography, Paper, Link } from '@mui/material';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
        this.state = {
            shift: false,
            l: false,
        }
    }

    handleKeyboardEvent(event){
        if(!(event.code === 'KeyL' || event.code === 'ShiftRight' || event.code === 'ShiftLeft')){
            return;
        }
        if (event.code === 'KeyL') {
            this.setState({l: event.type === 'keydown'})
        }
        else if (event.code === 'ShiftRight' || event.code === 'ShiftLeft') {
            this.setState({shift: event.type === 'keydown'})
        }
        const {shift, l} = this.state
        if(shift && l ){
            window.open('/login', "_self");
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyboardEvent.bind(this));
        document.addEventListener("keyup", this.handleKeyboardEvent.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyboardEvent.bind(this));
        document.removeEventListener("keyup", this.handleKeyboardEvent.bind(this));
    }     

    render() {
        return (
            <Container maxWidth="sm" sx={{ mt: 0 }}>
                <Paper elevation={0} sx={{ p: 3 , borderRadius: 0}}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        LCSB File Transfer
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        High-speed downloads and uploads provided by Aspera© software.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        If you want to have access, please check the 
                        <Link href="https://howto.lcsb.uni.lu/exchange-channels/lft/" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                            Documentation
                        </Link>.
                    </Typography>
                </Paper>
            </Container>
        );
    }
}

export default HomePage;
