import { CopyBlock, nord, dracula} from 'react-code-blocks';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    links: state.objects.links,
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
                {children}
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}




const Prerequisites = (
    <div>
        <p>
            Download and/or upload manager using Aspera FASP technology. 
            It requires to install the Aspera Connect© client from IBM™:
        </p>
        <p>
            <a href="https://www.ibm.com/aspera/connect/">Follow this link to download.</a>
        </p>
        <p>
            <a href="https://www.ibm.com/docs/en/aspera-connect">Follow this link to check the documentation.</a>
        </p>
        <p>
            Once Aspera Connect© is installed you will be able to upload and/or download the data regulated by your access rights.
        </p>
</div>
)

const ssh_file_name = 'aspera_tokenauth_id_rsa';
const loadtext = `module load tools/Aspera-CLI`;

const CommandLine = (
    <div>
        <p>
            Aspera Connect© should comes with the `ascp` command line tool preinstalled.<br/>
            You can still download it manually on the <a href="https://www.ibm.com/aspera/connect/">IBM website</a> and click on the "Download Aspera Connect [version]" button.
        </p>
        <p>
            In case of HPC, you need to load Aspera Connect module. It will automatically set `$EBROOTASPERAMINCLI` variable.
            <CopyBlock
                text={loadtext}
                theme={nord}
                language="bash"
            />
        </p>
        <p>
        To transfer data you also need to authenticate as an aspera transfer. For that you need an SSH private key.
The SSH private key comes with the Aspera Connect installation and is named `{ssh_file_name}`. Below are locations where the SSH private key can be found:
        </p>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Environment</TableCell>
                        <TableCell align="right">Location</TableCell>
                        <TableCell align="right">Alternate location</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow hover key={0} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>    
                            <TableCell component="th" scope="row">MacOS</TableCell>
                            <TableCell align="right">$HOME/Applications/IBM\ Aspera\ Connect.app/Contents/Resources/{ssh_file_name}</TableCell>
                            <TableCell align="right">/Applications/IBM\ Aspera\ Connect.app/Contents/Resources/{ssh_file_name}</TableCell>
                        </TableRow>

                        <TableRow hover key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Windows</TableCell>
                            <TableCell align="right">C:\\Program Files (x86)\IBM\Aspera Connect\etc\{ssh_file_name}</TableCell>
                            <TableCell align="right">C:\\Users\username\AppData\Local\Programs\IBM\Aspera Connect\etc\{ssh_file_name}</TableCell>
                        </TableRow>

                        <TableRow hover key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Linux</TableCell>
                            <TableCell align="right">$HOME/.aspera/connect/etc/{ssh_file_name}</TableCell>
                            <TableCell align="right">/opt/aspera/etc/{ssh_file_name}</TableCell>
                        </TableRow>

                        <TableRow hover key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">HPC @ Uni.lu</TableCell>
                            <TableCell align="right">$EBROOTASPERAMINCLI/etc/{ssh_file_name}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    </div>
)

const Upload = (current) => {
    if(!current.upload_token){
        return (
            <div>
                <h4>To Upload</h4>
                <Alert severity="warning">
                    You don't have the rights to upload data.
                </Alert>
            </div>
            );
    }
    const text = `# Export the Token
export TOKEN="${current.upload_token}"
# Execute the 'ascp' command. Replace '[file or directory]' by the path to the file you want to upload.
ascp -d -P 33001 -W $TOKEN -i $SSHKEY --file-crypt=encrypt [file or directory] $ASPERA_USERAME@aspera-hts-01.lcsb.uni.lu:/.`
    return (
        <div>
            <h4>To Upload</h4>
        <CopyBlock
            text={text}
            theme={nord}
            language="bash"
            />
        </div>
    );
}

const Download = (current) => {
    if(!current.download_token){
        return (
            <Alert severity="warning">
                You don't have the rights to download data.
            </Alert>
            );
    }
    const text = `# Export the Token
export TOKEN="${current.download_token}"
# Execute the 'ascp' command. Replace '[file or directory]' by the path to the file you want to download.
ascp -d -P 33001 -W $TOKEN -i $SSHKEY --file-crypt=decrypt $ASPERA_USERAME@aspera-hts-01.lcsb.uni.lu:/[file or directory] .`
        return (
            <div>
            <h4>To Download</h4>
            <CopyBlock
                text={text}
                theme={nord}
                language="bash"
            />
            </div>
        );
}


const Code = (current) => {
    if(!current.content_protection_password){
        return null;
    }
    const sshtext = `export SSHKEY="[path to ssh key]"`
    const text = `export ASPERA_SCP_FILEPASS="${current.content_protection_password}"
export ASPERA_USERAME="${current.remote_user}"
export ASPERA_SCP_PASS="743128bf-3bf3-45b5-ab14-4602c67f2950"
`
    return (
        <div>
            <h4>Export the path to the SSH key (see above for a valid location)</h4>
            <CopyBlock
                text={sshtext}
                theme={nord}
                language="bash"
            />
            <h4>Export the remaining environment variables</h4>
            <CopyBlock
                text={text}
                theme={nord}
                language="bash"
            />
            <p>
                Once the Aspera Connect is installed and environment variables are exported, follow the sections below for Upload or Download.
            </p>
            {Upload(current)}
            {Download(current)}
        </div>
        
        )
    }


class UserInfoHelp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, newValue) {
        this.setState({
            value: newValue
        })
    }

    render() {
        const {links, hashid} = this.props;
        const current = links[hashid];
        if(!current){
            return null;
        }
        //https://www.ibm.com/aspera/connect/
        const {value} = this.state;
        return (
            <Box sx={{ width: '100%' }}>
                <Box elevation={6} sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'white', borderRadius: 1 }}>
                    <Tabs 
                        value={value} 
                        onChange={this.handleChange} 
                        aria-label="basic tabs example"
                        sx={{
                            '& .MuiTab-root': {
                                color: 'text.secondary',
                                '&.Mui-selected': {
                                    color: 'primary.main',
                                    fontWeight: 'bold',
                                },
                            },
                            bgcolor: 'white',
                        }}
                    >
                        <Tab label="Installation and prerequisites" {...a11yProps(0)} />
                        <Tab label="Using the command line" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <Box sx={{ marginTop: 1 }}>
                    <Paper 
                        elevation={3}
                        sx={{
                            bgcolor: 'white',
                            borderRadius: 1,
                        }}
                    >
                        <TabPanel value={value} index={0}>
                            {Prerequisites}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {CommandLine}
                            {Code(current)}
                        </TabPanel>
                    </Paper>
                </Box>
            </Box>
        );
    }
}

export default wrapper(UserInfoHelp, {
    conn: [mapStateToProps, null],
    isAdmin: false,
});
